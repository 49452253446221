"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AjaxCredentials = /** @class */ (function () {
    function AjaxCredentials() {
        this.accessToken = "";
        this.refreshToken = "";
        this.accessTokenExpiry = new Date(0);
    }
    return AjaxCredentials;
}());
exports.default = AjaxCredentials;
