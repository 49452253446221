"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var AjaxError = /** @class */ (function (_super) {
    __extends(AjaxError, _super);
    function AjaxError(httpStatusCode, appErrorCode, m) {
        var _this = this;
        if (!m) {
            m = "HTTP Status " + httpStatusCode + " with app error code " + appErrorCode;
        }
        _this = _super.call(this, m) || this;
        Object.setPrototypeOf(_this, AjaxError.prototype);
        _this._httpStatusCode = httpStatusCode;
        _this._appErrorCode = appErrorCode;
        return _this;
    }
    Object.defineProperty(AjaxError.prototype, "httpStatusCode", {
        get: function () {
            return this._httpStatusCode;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AjaxError.prototype, "appErrorCode", {
        get: function () {
            return this._appErrorCode;
        },
        enumerable: false,
        configurable: true
    });
    return AjaxError;
}(Error));
exports.default = AjaxError;
