"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Entity_1 = require("./Entity");
var Ajax_1 = require("../util/Ajax");
var Organization = /** @class */ (function (_super) {
    __extends(Organization, _super);
    function Organization() {
        var _this = _super.call(this) || this;
        _this.name = "";
        _this.contactFirstname = "";
        _this.contactLastname = "";
        _this.contactEmail = "";
        _this.country = "";
        _this.language = "";
        return _this;
    }
    Organization.prototype.serialize = function () {
        return Object.assign(_super.prototype.serialize.call(this), {
            "name": this.name,
            "firstname": this.contactFirstname,
            "lastname": this.contactLastname,
            "email": this.contactEmail,
            "country": this.country,
            "language": this.language
        });
    };
    Organization.prototype.deserialize = function (input) {
        _super.prototype.deserialize.call(this, input);
        this.name = input.name;
        this.contactFirstname = input.firstname;
        this.contactLastname = input.lastname;
        this.contactEmail = input.email;
        this.country = input.country;
        this.language = input.language;
    };
    Organization.prototype.getBackendUrl = function () {
        return "/organization/";
    };
    Organization.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax_1.default.saveEntity(this, this.getBackendUrl()).then(function () { return _this; })];
            });
        });
    };
    Organization.prototype.delete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax_1.default.delete(this.getBackendUrl() + this.id).then(function () { return undefined; })];
            });
        });
    };
    Organization.prototype.getSubscriptionManagementURL = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax_1.default.get(this.getBackendUrl() + this.id + "/subscription/manage").then(function (res) {
                        return res.json.url;
                    })];
            });
        });
    };
    Organization.get = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax_1.default.get("/organization/" + id).then(function (result) {
                        var e = new Organization();
                        e.deserialize(result.json);
                        return e;
                    })];
            });
        });
    };
    Organization.list = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax_1.default.get("/organization/").then(function (result) {
                        var list = [];
                        result.json.forEach(function (item) {
                            var e = new Organization();
                            e.deserialize(item);
                            list.push(e);
                        });
                        return list;
                    })];
            });
        });
    };
    Organization.getOrgForDomain = function (domain) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax_1.default.get("/organization/domain/" + domain).then(function (result) {
                        var e = new Organization();
                        e.deserialize(result.json);
                        return e;
                    })];
            });
        });
    };
    return Organization;
}(Entity_1.Entity));
exports.default = Organization;
