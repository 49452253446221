"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var JwtDecoder = /** @class */ (function () {
    function JwtDecoder() {
    }
    JwtDecoder.getPayload = function (jwt) {
        var tokens = jwt.split(".");
        if (tokens.length != 3) {
            return null;
        }
        var payload = window.atob(tokens[1]);
        var json = JSON.parse(payload);
        return json;
    };
    return JwtDecoder;
}());
exports.default = JwtDecoder;
