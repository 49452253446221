"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Entity = void 0;
var Entity = /** @class */ (function () {
    function Entity(id) {
        this.id = "";
        if (id) {
            this.id = id;
        }
    }
    Entity.prototype.serialize = function () {
        return {};
    };
    Entity.prototype.deserialize = function (input) {
        this.id = input.id;
    };
    return Entity;
}());
exports.Entity = Entity;
