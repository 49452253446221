"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MergeRequest = /** @class */ (function () {
    function MergeRequest(id, email, userId) {
        this.id = "";
        this.email = "";
        this.userId = "";
        this.id = id;
        this.email = email;
        this.userId = userId;
    }
    return MergeRequest;
}());
exports.default = MergeRequest;
