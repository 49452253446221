"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var AjaxError_1 = require("./AjaxError");
var async_mutex_1 = require("async-mutex");
var AjaxCredentials_1 = require("./AjaxCredentials");
var AjaxConfigBrowserPersister_1 = require("./AjaxConfigBrowserPersister");
var Ajax = /** @class */ (function () {
    function Ajax() {
    }
    Ajax.getBackendUrl = function () {
        var url = Ajax.URL.trim();
        if (url.endsWith("/")) {
            url = url.substring(0, url.length - 1);
        }
        return url;
    };
    Ajax.query = function (method, url, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log("AJAX request for: " + url);
                url = Ajax.getBackendUrl() + url;
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var performRequest = function () {
                            var options = Ajax.getFetchOptions(method, Ajax.CREDENTIALS.accessToken, data);
                            fetch(url, options).then(function (response) {
                                if (response.status >= 200 && response.status <= 299) {
                                    response.json().then(function (json) {
                                        resolve(Ajax.getAjaxResult(json, response));
                                    }).catch(function (err) {
                                        resolve(Ajax.getAjaxResult({}, response));
                                    });
                                }
                                else {
                                    var appCode = response.headers.get("X-Error-Code");
                                    reject(new AjaxError_1.default(response.status, appCode ? parseInt(appCode) : 0));
                                }
                            }).catch(function (err) {
                                reject(err);
                            });
                        };
                        if (Ajax.CREDENTIALS.refreshToken) {
                            Ajax.refreshAccessToken(Ajax.CREDENTIALS.refreshToken).then(function (cred) {
                                performRequest();
                            }).catch(function (err) {
                                reject(new AjaxError_1.default(401, 0));
                            });
                        }
                        else {
                            performRequest();
                        }
                    })];
            });
        });
    };
    Ajax.refreshAccessToken = function (refreshToken) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                // Acquire mutex so that refreshing the token is not refreshed concurrently
                return [2 /*return*/, Ajax.REFRESH_TOKEN_MUTEX.acquire().then(function (release) {
                        return new Promise(function (resolve, reject) {
                            // Once it's our turn, check if we really need to refresh the token
                            if (new Date().getTime() < Ajax.CREDENTIALS.accessTokenExpiry.getTime()) {
                                // Token is still valid, nothing to do
                                release();
                                resolve(Ajax.CREDENTIALS);
                            }
                            else {
                                // Refresh the token
                                console.log("Performing access token refresh with refresh token: " + refreshToken);
                                var data = {
                                    "refreshToken": refreshToken
                                };
                                var options = Ajax.getFetchOptions("POST", null, data);
                                var url = Ajax.getBackendUrl() + Ajax.REFRESH_URL;
                                fetch(url, options).then(function (response) {
                                    if (response.status >= 200 && response.status <= 299) {
                                        response.json().then(function (json) {
                                            var c = {
                                                accessToken: json.accessToken,
                                                refreshToken: json.refreshToken,
                                                accessTokenExpiry: new Date(new Date().getTime() + Ajax.ACCESS_TOKEN_EXPIRY_OFFSET)
                                            };
                                            Ajax.CREDENTIALS = c;
                                            Ajax.PERSISTER.updateCredentialsSessionStorage(c).then(function () {
                                                Ajax.PERSISTER.readRefreshTokenFromLocalStorage().then(function (refreshTokenConfig) {
                                                    if (refreshTokenConfig.refreshToken) {
                                                        Ajax.PERSISTER.persistRefreshTokenInLocalStorage(c).then(function () {
                                                            release();
                                                            resolve(c);
                                                        });
                                                    }
                                                    else {
                                                        release();
                                                        resolve(c);
                                                    }
                                                });
                                            });
                                        }).catch(function (err) {
                                            release();
                                            reject(new AjaxError_1.default(response.status, 0));
                                        });
                                    }
                                    else {
                                        // token invalid
                                        Ajax.CREDENTIALS = new AjaxCredentials_1.default();
                                        Ajax.PERSISTER.deleteCredentialsFromSessionStorage().then(function () {
                                            resolve(Ajax.CREDENTIALS);
                                            release();
                                        });
                                    }
                                }).catch(function (err) {
                                    release();
                                    reject(err);
                                });
                            }
                        });
                    })];
            });
        });
    };
    Ajax.getAjaxResult = function (json, response) {
        var objectId = "";
        if (response.headers.get("X-Object-Id") != null) {
            objectId = String(response.headers.get("X-Object-Id"));
        }
        var res = {
            json: json,
            status: response.status,
            objectId: objectId
        };
        return res;
    };
    Ajax.getFetchOptions = function (method, accessToken, data) {
        var headers = new Headers();
        if (accessToken) {
            headers.append("Authorization", "Bearer " + accessToken);
        }
        if (data && !(data instanceof File)) {
            headers.append("Content-Type", "application/json");
        }
        var options = {
            method: method,
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: headers
        };
        if (data) {
            if (data instanceof File) {
                options.body = data;
            }
            else {
                options.body = JSON.stringify(data);
            }
        }
        return options;
    };
    Ajax.postData = function (url, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax.query("POST", url, data)];
            });
        });
    };
    Ajax.putData = function (url, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax.query("PUT", url, data)];
            });
        });
    };
    Ajax.head = function (url, params) {
        return __awaiter(this, void 0, void 0, function () {
            var s, k;
            return __generator(this, function (_a) {
                if (params) {
                    s = "";
                    for (k in params) {
                        if (s !== "") {
                            s += "&";
                        }
                        s += k + "=" + encodeURIComponent(params[k]);
                    }
                    url += "?" + s;
                }
                return [2 /*return*/, Ajax.query("HEAD", url, null)];
            });
        });
    };
    Ajax.saveEntity = function (e, url) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!url.endsWith("/")) {
                    url += "/";
                }
                if (e.id) {
                    return [2 /*return*/, Ajax.putData(url + e.id, e.serialize())];
                }
                else {
                    return [2 /*return*/, Ajax.postData(url, e.serialize()).then(function (result) {
                            e.id = result.objectId;
                            return result;
                        })];
                }
                return [2 /*return*/];
            });
        });
    };
    Ajax.get = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax.query("GET", url)];
            });
        });
    };
    Ajax.delete = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Ajax.query("DELETE", url)];
            });
        });
    };
    Ajax.URL = "";
    Ajax.CREDENTIALS = new AjaxCredentials_1.default();
    Ajax.ACCESS_TOKEN_EXPIRY_OFFSET = 60 * 5 * 1000; // 5 minutes
    Ajax.PERSISTER = new AjaxConfigBrowserPersister_1.default();
    Ajax.REFRESH_URL = "/auth/refresh";
    Ajax.REFRESH_TOKEN_MUTEX = new async_mutex_1.Mutex();
    return Ajax;
}());
exports.default = Ajax;
