import React from 'react';
import { Loader as IconLoad } from 'react-feather';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface State {
}

interface Props {
    t: TFunction
    showText: boolean
    paddingTop: boolean
}

class Loading extends React.Component<Props, State> {
    render() {
        return (
            <div className={this.props.paddingTop === undefined || this.props.paddingTop === true ? "padding-top center" : "center"}><IconLoad className="feather loader" />{this.props.showText === undefined || this.props.showText === true ? " " + this.props.t("loadingHint") : ""}</div>
        );
    }
}

export default withTranslation()(Loading as any);
