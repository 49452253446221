"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Formatting = /** @class */ (function () {
    function Formatting() {
    }
    Formatting.t = function (s, vars) {
        var res = Formatting.I18n[Formatting.Language][s];
        if (!res) {
            return s;
        }
        if (vars) {
            for (var k in vars) {
                res = res.replaceAll("{{" + k + "}}", vars[k]);
            }
        }
        return res;
    };
    Formatting.tbool = function (s) {
        var res = Formatting.I18n[Formatting.Language][s];
        return res;
    };
    Formatting.getFormatter = function (local) {
        var formatter = new Intl.DateTimeFormat(Formatting.Language, {
            timeZone: local ? undefined : 'UTC',
            weekday: 'long',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: this.tbool("hour12")
        });
        return formatter;
    };
    Formatting.getFormatterNoTime = function (local) {
        var formatter = new Intl.DateTimeFormat(Formatting.Language, {
            timeZone: local ? undefined : 'UTC',
            weekday: 'long',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        return formatter;
    };
    Formatting.getFormatterShort = function (local) {
        var formatter = new Intl.DateTimeFormat(Formatting.Language, {
            timeZone: local ? undefined : 'UTC',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        });
        return formatter;
    };
    Formatting.getFormatterDate = function (local) {
        var formatter = new Intl.DateTimeFormat(Formatting.Language, {
            timeZone: local ? undefined : 'UTC',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        return formatter;
    };
    Formatting.getDayValue = function (date) {
        var s = date.getFullYear().toString().padStart(4, "0") + (date.getMonth() + 1).toString().padStart(2, "0") + date.getDate().toString().padStart(2, "0");
        return parseInt(s);
    };
    Formatting.getISO8601 = function (date) {
        var s = date.getFullYear().toString().padStart(4, "0") + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");
        return s;
    };
    Formatting.getDateOffsetText = function (enter, leave) {
        var today = Formatting.getDayValue(new Date());
        var start = Formatting.getDayValue(enter);
        var end = Formatting.getDayValue(leave);
        if (start <= today && today <= end) {
            return Formatting.t("today");
        }
        if (start == today + 1) {
            return Formatting.t("tomorrow");
        }
        if (start > today && start <= today + 7) {
            return Formatting.t("inXdays", { "x": (start - today) });
        }
        return Formatting.getFormatterDate().format(enter);
    };
    Formatting.convertToFakeUTCDate = function (d) {
        return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), 0));
    };
    Formatting.stripTimezoneDetails = function (s) {
        if ((s.length > 6) && ((s[s.length - 6] === "+") || (s[s.length - 6] === "-"))) {
            return s.substring(0, s.length - 6) + ".000Z";
        }
        return s;
    };
    Formatting.Language = "de";
    Formatting.I18n = {
        "de": {
            "today": "Heute",
            "tomorrow": "Morgen",
            "inXdays": "In {{x}} Tagen",
            "hour12": false,
        },
        "en": {
            "today": "Today",
            "tomorrow": "Tomorrow",
            "inXdays": "In {{x}} days",
            "hour12": true,
        },
    };
    return Formatting;
}());
exports.default = Formatting;
